import React, {useState} from "react"
import type { HeadFC, PageProps } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FaArrowRight } from "react-icons/fa"

import Layout from "../components/layout"
import PageBanner from "../components/pagebanner"
import Seo from "../components/seo"
import ContactUsForm from "../components/forms/contactus"
import CaseStudy from "../components/homepage/casestudy"
import Quotes from "../components/homepage/quotes"
import Stats from "../components/homepage/stats"

const ServicesPage: React.FC<PageProps> = () => {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  
  return (
    <Layout>
      <PageBanner><span className="colored">Our</span> Services</PageBanner>
      
      <div id="fh5co-testimonial">
        <div className="container">
          <div id="marketing" className="row">
            <div className="col-md-6 col-md-offset-3 text-center fh5co-heading">
              <h2>Marketing &amp; Advertising</h2>
              
              <p>We use a statistics first approach to identify where you are losing sales. Do they click off immediately? Do they get to your contact page and give up? Do some advertising sources work better for you? We find out in our deep dive conversation.</p>
              
              <div><StaticImage src="../images/stock-gf2db427ef_1280.jpg" alt="Google Analytics" width={1280} height={850} /></div>
              
              <button className="btn btn-primary btn-outline with-arrow btn-sm" onClick={() => setIsContactFormOpen(true)}>Contact Us <FaArrowRight /></button>
            </div>
          </div>
          
          <div id="ecommerce-optimization" className="row">
            <div className="col-md-6 col-md-offset-3 text-center fh5co-heading">
              <h2>Ecommerce Optimization</h2>
              
              <p>An extension of Marketing &amp; Advertising, ecommerce stores present a slightly different sales process. The conventional movement through the CRM from warm lead to follow up emails to sales is instead replaced with the ecommerce engine&apos;s native account management. This requires a different plugin mixture but also provides new opportunities for conversion optimization. We will also review whether the current ecommerce engine has the customization options desired. Many of the engines targeted at small businesses are quickly outgrown or suffer from bloat and slowdown when too many plugins are installed.</p>
              
              <div><StaticImage src="../images/ecommerce-gb330ab439_1280.jpg" alt="Google Analytics" width={1280} height={850} /></div>
              
              <button className="btn btn-primary btn-outline with-arrow btn-sm" onClick={() => setIsContactFormOpen(true)}>Contact Us <FaArrowRight /></button>
            </div>
          </div>
          
          <div id="site-optimization" className="row">
            <div className="col-md-6 col-md-offset-3 text-center fh5co-heading">
              <h2>Site Optimization</h2>
              
              <p>We have in-house development resources so that we can optimize not just the advertisement but the entire site experience. That can range from making sure the site loads faster through to implementing unique page components designed to increase conversion rates and drive sales to higher margin products on top of a customer&apos;s initial cart. Google now penalize slow loading sites and mobile users are very likely to click the back button on sites that don&apos;t load within 5 seconds.</p>
              
              <div><StaticImage src="../images/office-g399e9a00d_1280.jpg" alt="Google Analytics" width={1280} height={850} /></div>
              
              <button className="btn btn-primary btn-outline with-arrow btn-sm" onClick={() => setIsContactFormOpen(true)}>Contact Us <FaArrowRight /></button>
            </div>
          </div>
          
          <div id="crm-management" className="row">
            <div className="col-md-6 col-md-offset-3 text-center fh5co-heading">
              <h2>CRM Management</h2>
              
              <p>We build a CRM for you to keep track of your leads. Did anyone send a follow up email to that person who enquired months ago? Now you can know. We can also help eliminate internal paperwork and spreadsheets by converting them to a modern CRM system.</p>
              
              <div><StaticImage src="../images/whatsapp-interface-g20fd5d303_1280.png" alt="CRM Customization" width={1280} height={734} /></div>
              
              <button className="btn btn-primary btn-outline with-arrow btn-sm" onClick={() => setIsContactFormOpen(true)}>Contact Us <FaArrowRight /></button>
            </div>
          </div>
        </div>
      </div>
      
      <ContactUsForm baseName="services" isOpen={isContactFormOpen} fnClose={() => setIsContactFormOpen(false)} />
    </Layout>
  )
}

export default ServicesPage

const seo = {
  metaDesc: "PlexTech Marketing - Our Services",
}

export const Head = () => <Seo title="PlexTech Marketing" type="website" url="/services/" seo={seo} />
